<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon small color="white" class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%"
               max-height="70px"
        />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">
            Établissement
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ establishment ? 'Modifier les informations de l\'établissement' : 'Créer un nouvel établissement' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="establishment" class="mt-2" @submit.prevent="submit">
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <text-field v-model="model.label" rounded
                          label="Nom de l'établissement"
                          :rules="[$rules.required, $rules.maxLength(150)]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <text-field v-model="model.finess" rounded label="Finess"
                          :rules="[$rules.required, $rules.maxLength(150)]"
              />
            </v-col>
            <v-col cols="12">
              <text-field v-model="model.address.street" rounded
                          label="Adresse de l'établissement" :rules="[$rules.maxLength(240)]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <text-field v-model="model.address.zipCode" rounded
                          label="Code postal" :rules="[$rules.maxLength(10)]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <text-field v-model="model.address.city" rounded
                          label="Ville" :rules="[$rules.maxLength(150)]"
              />
            </v-col>
            <v-col cols="12">
              <text-field v-model="model.address.country" rounded
                          label="Pays" :rules="[$rules.maxLength(100)]"
              />
            </v-col>
            <v-col cols="12">
              <v-switch v-model="model.surveyFeatureActivated" class="mt-1"
                        label="Activer les questionnaires" color="secondary" outlined dense
                        hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-card-actions class="px-3">
            <v-spacer />
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn x-small rounded color="success" type="submit" :loading="submitting" :disabled="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import { create, update } from '@/modules/establishments/api/establishments'
  import { mapActions } from 'vuex'
  import TextField from '@/modules/core/components/TextField'

  export default {
    name: 'EstablishmentForm',
    components: {TextField},
    props: {
      establishment: Object,
      value: Object
    },
    data() {
      return {
        submitting: false,
        successDialog: false,
        model: {
          label: this.establishment ? this.establishment.label : '',
          finess: this.establishment ? this.establishment.finess : '',
          address: {
            street: this.establishment ? this.establishment.address.street : null,
            zipCode: this.establishment ? this.establishment.address.zipCode : null,
            city: this.establishment ? this.establishment.address.city : null,
            country: this.establishment ? this.establishment.address.country : null
          },
          surveyFeatureActivated: this.establishment ? this.establishment.surveyFeatureActivated : false
        }
      }
    },
    methods: {
      ...mapActions('establishments', ['createdEstablishment']),
      async submit() {
        if (!this.$refs.establishment.validate()) {
          return
        }
        try {
          this.submitting = true
          let {data} = this.establishment ? await update(this.establishment.id, this.model) : await create(this.model)
          this.establishment ? this.$emit('update-establishment', data) : this.$emit('add-establishment', data)
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.establishment ? 'Établissement modifié avec succès.' : 'Établissement crée avec succès.'
          })
          this.$store.commit('establishments/upsertEstablishment', data)
          this.$emit('close')
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
          throw e
        } finally {
          this.submitting = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
