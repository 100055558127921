<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-card-title class=" font-alt pb-3">Établissements</v-card-title>
    <v-card-subtitle class="o-60">Liste des établissements activés et désactivés</v-card-subtitle>
    <skeleton-list v-if="loading" />
    <v-list v-if="establishments && !loading" class="pa-0">
      <v-list-item two-line class="click-to-create " :class="$vuetify.theme.dark ? '' : 'grey lighten-4'" @click.stop="establishmentForm = true">
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'plus']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">Ajouter un établissement</v-list-item-title>
          <v-list-item-subtitle class="op-60 font-weight-light">Créer un nouvel établissement</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <empty-list v-if="establishments.length === 0" title="Aucun établissement" subtitle="Aucun établissement à gèrer." />
      <template v-for="(establishment, index) in establishments">
        <v-list-item :key="establishment.id" two-line @click.stop="select(establishment)">
          <v-list-item-avatar>
            <v-avatar color="grey lighten-2" size="40" class="white--text">
              {{ nameToInitials(establishment.label) }}
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-alt font-weight-normal">{{ establishment.label }}</v-list-item-title>
            <v-list-item-subtitle class="op-60 font-weight-light">{{ establishment.address ? addressDisplayer(establishment.address) : 'Non renseigné' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < establishments.length -1" :key="index" />
      </template>
      <div v-if="totalItems > itemsPerPage" class="py-3 d-flex justify-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="5"
          color="grey"
          @input="loadEstablishments"
        />
      </div>
    </v-list>
    <establishment-form v-if="establishmentForm" :establishment="item" @close="closeDialog" @update-establishment="updateListItem" @add-establishment="addListItem" />
  </v-card>
</template>

<script>
  import EmptyList from '@/modules/core/components/EmptyList'
  import SkeletonList from '@/modules/core/components/SkeletonList'
  import * as establishments from '@/modules/establishments/api/establishments'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import EstablishmentForm from '@/modules/establishments/components/EstablishmentForm'
  import {removeItem} from '@/utils/array'

  export default {
    name: 'EstablishmentsList',
    components: {EstablishmentForm, SkeletonList, EmptyList},
    mixins: [dataDisplayers],
    data() {
      return {
        loading: false,
        establishments: null,
        establishmentForm: false,
        item: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalItems: 0
      }
    },
    created() {
      this.loadEstablishments()
    },
    methods: {
      closeDialog() {
        this.establishmentForm = false
        this.item = null
        this.remove()
      },
      loadEstablishments() {
        this.loading = true
        try {
          establishments.list({
            params: {
              page: this.page,
              itemsPerPage: this.itemsPerPage
            }
          }).then((response) => {
            this.establishments = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
        }
      },
      updateListItem(data){
        if (data) {
          Object.assign(this.establishments[this.establishments.indexOf(this.item)], data)
        }
        return false
      },
      addListItem(data){
        if (data) {
          this.establishments.push(data)
        }
        return false
      },
      select(establishment) {
        this.establishmentForm = true
        this.item = establishment
      },
      remove(establishment) {
        removeItem(this.establishments, establishment)
      }
    }
  }
</script>
